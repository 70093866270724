
import { Component, Vue } from "vue-property-decorator";
import Language from "./Language.vue";
import Theme from "./Theme.vue";

@Component({
  components: {
    Language,
    Theme,
  },
  i18n: {
    messages: {
      en: {
        home: "Home",
        projects: "Projects",
      },
      sk: {
        home: "Domov",
        projects: "Projekty",
      },
    },
  },
})
export default class Navigation extends Vue {}
