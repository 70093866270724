
import { Prop, Component, Vue } from "vue-property-decorator";

@Component({
  i18n: {
    messages: {
      en: {
        homepage: "Homepage",
        noDescription: "No description",
      },
      sk: {
        homepage: "Domovská stránka",
        noDescription: "Bez popisu",
      },
    },
  },
})
export default class Projects extends Vue {
  @Prop(Object) readonly project: object | undefined;
}
