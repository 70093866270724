
import Project from "@/components/Project.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    Project,
  },
  i18n: {
    messages: {
      en: {
        language: "Language",
        all: "All",
      },
      sk: {
        language: "Jazyk",
        all: "Všetko",
      },
    },
  },
})
export default class Projects extends Vue {
  // Data

  projects = [];
  filterLanguage = "";

  // Lifecycle Hooks

  created() {
    if (this.$data.projects.length) return;
    fetch("https://api.github.com/users/Redpoint1/repos?sort=created")
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        this.$data.projects = json;
      });
  }

  // Computed

  get filteredProjects() {
    if (this.$data.filterLanguage) {
      return this.$data.projects.filter((item: any) => {
        return item.language === this.$data.filterLanguage;
      });
    }
    return this.$data.projects;
  }

  get languages() {
    const all = { text: this.$t("all"), value: "" };
    const languages = new Set();

    languages.add(all);

    for (let project of this.$data.projects) {
      if (project.language) {
        languages.add({ text: project.language, value: project.language });
      }
    }
    return Array.from(languages);
  }

  get loaded() {
    return this.languages.length !== 1;
  }

  // Methods

  enter(el: any, done: any) {
    var delay = el.dataset["index"] * 100;
    setTimeout(function () {
      el.style.opacity = "1";
      done();
    }, delay);
  }
}
