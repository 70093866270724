
import { Component, Vue } from "vue-property-decorator";

@Component({
  i18n: {
    messages: {
      en: {
        reportABug: "Report a bug",
        copyright: "Copyright",
      },
      sk: {
        reportABug: "Nahlásiť chybu",
        copyright: "Všetky práva vyhradené",
      },
    },
  },
})
export default class Footer extends Vue {
  get author() {
    return process.env.VUE_APP_AUTHOR;
  }

  get copyright() {
    return process.env.VUE_APP_COPYRIGHT;
  }

  get version() {
    return process.env.VUE_APP_VERSION;
  }

  get bugUrl() {
    return process.env.VUE_APP_BUG_REPORT;
  }
}
